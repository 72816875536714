<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결과 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-plant 
                  required
                  :disabled="disabled"
                  :editable="editable && !isOld" 
                  type="edit" 
                  name="plantCd" 
                  v-model="patrol.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  required
                  type="edit"
                  codeGroupCd="PATROL_TYPE_FST_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="상위구분"
                  name="patrolFstCd"
                  v-model="patrol.patrolFstCd">
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :required="patrol.patrolFstCd === 'PTF0000001' || patrol.patrolFstCd === 'PTF0000005'"
                  type="edit" 
                  codeGroupCd="PATROL_TYPE_SEC_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="하위구분"
                  name="patrolSecCd"
                  v-model="patrol.patrolSecCd">
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 추진부서 -->
                <c-dept
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="추진부서"
                  name="deptCd"
                  v-model="patrol.deptCd"
                  @setDeptName="setDeptName"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="추진일정"
                  type="date"
                  name="patrolDate"
                  v-model="patrol.patrolDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="비고"
                  name="remark"
                  v-model="patrol.remark"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- gridHeight="430px" -->
          <c-table
            ref="grid"
            title="지적사항 및 개선목록"
            :columns="grid.columns"
            :data="grid.data"
            :isTitle="true"
            :editable="false"
            :changeData="changeData"
            :gridHeight="gridHeight"
            :usePaging="false"
            :filtering="false"
            :columnSetting="false"
            @linkClick="(row, col, idx) => linkClick(row, row.ibmClassCd, row.sopImprovementId)"
          >
            <template slot="suffixTitle">
              <font v-if="!isOld" class="text-negative" style="font-size:0.8em;font-weight:300;">
                ※ 먼저 신규 저장을 하세요.
              </font>
            </template>
            <template slot="table-button">
              <q-btn-group outline >
                  <!-- 개선요청 -->
                <c-btn 
                  v-if="!disabled&&isOld" 
                  label="개선요청" 
                  color="red-6"
                  @btnClicked="addImpr" />
                  <!-- 즉시조치 -->
                <c-btn 
                  v-if="!disabled&&isOld" 
                  label="즉시조치" 
                  color="light-blue"
                  text-color="white"
                  @btnClicked="addImmImpr" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn 
            :flat="true"
            v-if="isOld && !disabled && editable && !popupParam.isApprContent" 
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="removePlan" />
          <c-btn
            :flat="true"
            v-show="!disabled && editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="patrol"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="savePlan"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'patrol-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        isApprContent: false,
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      patrol: {
        saiPatrolId: '',
        plantCd: '',
        deptCd: '',
        deptName: '',
        patrolFstCd: null,
        patrolSecCd: null,
        patrolDate: '',
        patrolName: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        remark: '',
        regUserId: '',
        chgUserId: '',
        patrolImproveModelList: [],
      },
      grid: {
        columns: [
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            // 구분
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: '제목',
            align: 'left',
            sortable: false,
            type: 'link',
          },
        ],
        data: [],
        height: '',
      },
      editable: true,
      saveType: 'POST',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      changeData: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 250) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.saiPatrolId)
    },
    disabled() {
      return this.patrol.patrolCompleteFlag === "Y"
        // 결재중인 경우 비활성화
        || (this.patrol.approvalStatusCd === 'ASC0000001' && !this.popupParam.isApprContent)
    },
    imprTabInfo() {
      return {
        key: this.popupParam.saiPatrolId, // 
        ibmTaskTypeCd: 'ITT0000065',
        ibmTaskUnderTypeCd: 'ITTU000105',
        requestContents: this.patrol.patrolName,
      }
    },
    // approvalInfo() {
    //   return {
    //     sysApprovalRequestId: this.patrol.sysApprovalRequestId, // 결재요청번호
    //     /**
    //      * 결재상태코드
    //      * ASC0000001	결재중
    //      * ASC0000002	반려
    //      * ASC9999999	결재완료
    //      */
    //     approvalStatusCd: this.patrol.approvalStatusCd, 
    //     apprEditable: this.editable
    //       && this.isOld 
    //       && !this.disabled, // 결재버튼 활성화 기준
    //     param: this.patrol, // 결재 param
    //     approvalUrl: this.approvalUrl, // 결재 url
    //     isApproval: this.isApproval, // 결재 submit
    //     approvalTypeCd: 'APTC000019', // 결재유형코드
    //     approvalParamValue: { // 결재상세화면 파라미터
    //       saiPatrolId: this.patrol.saiPatrolId,
    //       isApprContent: true
    //     },
    //     approvalRequestName: this.patrol.patrolName, // 결재요청명 (문서 title)
    //     approvalConnId: this.patrol.saiPatrolId, // 결재연결용 업무일련번호 (문서 key)
    //   }
    // },
    // [E] 결재관련 버튼 컨트롤
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.patrol.patrolDate = this.$comm.getToday();
      // url setting
      this.detailUrl = selectConfig.sai.patrol.get.url;
      this.saveUrl = transactionConfig.sai.patrol.insert.url;
      this.deleteUrl = transactionConfig.sai.patrol.delete.url;
      this.approvalUrl = transactionConfig.sai.patrol.update.url;
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;

      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiPatrolId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.patrol, _result.data);
          this.$emit('setRegInfo', _result.data)
          this.getImprList();
        },);
      }
    },
    getImprList() {
      if (this.imprTabInfo.key) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd, 
          ibmTaskUnderTypeCd: this.imprTabInfo.ibmTaskUnderTypeCd, 
          relationTableKey: this.imprTabInfo.key
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          this.changeData = !this.changeData
        },);
      }
    },
    savePlan() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.patrol.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.patrol.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.patrol.regUserId = this.$store.getters.user.userId
              this.patrol.chgUserId = this.$store.getters.user.userId

              this.patrol.patrolName = this.patrol.deptName + ' ' + this.patrol.patrolDate

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'saiPatrolId', _result.data)
      }
      this.getDetail();
    },
    /* eslint-disable no-unused-vars */ 
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiPatrolId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
        ibmTaskUnderTypeCd: this.imprTabInfo.ibmTaskUnderTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addImmImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
        ibmTaskUnderTypeCd: this.imprTabInfo.ibmTaskUnderTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, ibmClassCd, sopImprovementId) {
      if (ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선요청';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        sopImprovementId: sopImprovementId,
      };
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImprList();
    },
    setDeptName(deptName) {
      this.patrol.deptName = deptName
    },
  }
};
</script>
